<template>
    <div class="page-loader position-absolute d-flex align-items-center justify-content-center w-100 h-100 bg-white">
        <b-spinner variant="primary"/>
    </div>
</template>

<script>
export default {
    name: 'PageLoader'
}
</script>

<style
    lang="sass"
    scoped
>
.page-loader
    top: 0
    left: 0
    opacity: .75
</style>
