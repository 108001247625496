<template>
    <ConsignmentNoteCreateForm :edit="true"/>
</template>

<script>
import ConsignmentNoteCreateForm from '@/components/ConsignmentNote/ConsignmentNoteCreateForm'

export default {
    name: "CreateOrder",
    components: {
        ConsignmentNoteCreateForm
    },
    computed: {
        currentConsignmentNote () {
            return this.$store.state.consignmentNotes.currentConsignmentNote
        },
        breadcrumbs () {
            return [
                { title: 'Главная', link: '/' },
                { title: 'Заказ ПО', link: '/' },
                { title: 'Перечень товарных накладных по подряду', link: '/consignment-notes' },
                { title: `Товарная накладная № ${this.currentConsignmentNote.number} от ${this.$options.filters.parseDate(this.currentConsignmentNote.date)}`, link: `/consignment-notes/${this.$route.params.id}` },
                { title: 'Редактирование' }
            ]
        }
    }
}
</script>

<style scoped>

</style>
